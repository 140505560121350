import React, { Component } from 'react';
import {Auth} from 'aws-amplify';
import StackList from './StackList';
import Filter from './Filter'
import axios from 'axios';
import { properties } from '../properties.js';
import {NavLink} from 'react-router-dom';

class StacksApp extends Component {
  stackToAdd = {name: "", estimatedMinutesToSpinUp: "", stackItems: [], schedule: false, scheduleDate: "", scheduleTime: "", scheduleFrequency: "", stopResourcesAfter: ""};
  state = { stacks : [], filter_str: "", isAdding : false, stackToAdd: this.stackToAdd };
  rawList = [];

  constructor(props) {
    super(props);

    Auth.currentSession().then(session => {
      console.log('session loaded on first try')
      this.fillList(session.idToken.jwtToken);
    }).catch(e => {
      console.log('error while retrieving session, try again');
      console.log(e);
      Auth.currentSession().then(session => {
        console.log('session loaded on second try')
        this.fillList(session.idToken.jwtToken);
      }).catch(e => {
        console.log('error while retrieving session, give up');
        console.log(e);
      });
    });
  }

  fillList(idToken) {
    // add the IdToken as a header for api calls (via axios)
    console.log(idToken);
    axios.defaults.headers.common['Authorization'] = idToken;
    // retrieve all the test-stacks
    axios.get(properties.baseApiUrl+'/bringup-api')
      .then(resp => {
        this.rawList = resp.data;
        var stacks = this.filterData(this.rawList, "");
        stacks = this.addScheduleWhereMissing(stacks);
        this.setState({stacks : stacks});
      })
      .catch(e => {
        console.log('error while retrieving stacks');
        console.log(e);
      });
  }

  addScheduleWhereMissing = (stacks) => {
    return stacks.map(stack => {
      if (!stack.schedule) {
        stack.schedule = false;
        stack.scheduleDate = "";
        stack.scheduleTime = "";
        stack.scheduleFrequency = "";
      }
      return stack;
    });
  }

  filterData(stacks, search) {
    function filter_name(st) {
      return st.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
    }
    function sort_by_stackName(a, b) {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }
    return stacks.filter(filter_name).sort(sort_by_stackName);
  }

  filterChange = (str) => {
    this.setState({stacks : this.filterData(this.rawList, str)});
  }

  startAdding = (event) => {
    this.setState({isAdding : true});
  }

  cancelAdding = (event) => {
    // reset stack to add in case this action cancels an attempt to duplicate a stack
    this.setState({isAdding : false, stackToAdd: this.stackToAdd});
  }

  confirmAdding = (resource) => (event) => {
    var formattedResource = {...resource, name: (resource.name && resource.name.trim())};

    console.log ("adding");
    // add resource
    Auth.currentSession().then(session => {
      console.log('session loaded on first try')
      this.addStack(session.idToken.jwtToken, formattedResource);
    }).catch(e => {
      console.log('error while retrieving session, try again');
      console.log(e);
      Auth.currentSession().then(session => {
        console.log('session loaded on second try')
        this.addStack(session.idToken.jwtToken, formattedResource);
      }).catch(e => {
        console.log('error while retrieving session, give up');
        console.log(e);
      });
    });
  }

  addStack(idToken, newStack) {
    // add the IdToken as a header for api calls (via axios)
    axios.defaults.headers.common['Authorization'] = idToken;

    // update the stack
    axios.post(properties.baseApiUrl+'/bringup-api', newStack)
      .then(resp => {
        var lst = JSON.parse(JSON.stringify(this.state.stacks));
        lst.unshift(newStack);
        // also reset stack to add in case the add was a result of a copy
        this.setState({isAdding : false, stacks : lst, stackToAdd: {name: "", estimatedMinutesToSpinUp: "", stackItems: []}});
      })
      .catch(e => {
        this.setState({error : e.response.data});
      });
  }

  startDuplication = (resource) => (event) => {
    this.setState({isAdding : true, stackToAdd: resource});
  }

  render() {
    return (
      <div>
        <Filter onChange={this.filterChange} />

        <ul className="nav nav-pills">
          <li role="presentation"><NavLink to='/admin' exact activeClassName="hidden" className='btn btn-default navbar-btn'>Switch to Edit Mode</NavLink></li>
          <li role="presentation"><NavLink to='/' exact activeClassName="hidden" className='btn btn-default navbar-btn'>Switch to View Mode</NavLink></li>
          {this.props.isAdminMode && <li role="presentation">
            <button onClick={this.startAdding} className="btn btn-default navbar-btn">Add Stack</button>
          </li>}
        </ul>
        {this.state.error && <div className="alert alert-danger box-msg" role="alert">Failed: {this.state.error}</div>}
        <StackList 
          stacks={this.state.stacks}
          isAdminMode={this.props.isAdminMode}
          isAdding={this.state.isAdding}
          stackToAdd={this.state.stackToAdd}
          onCancelAdd={this.cancelAdding}
          onConfirmAdd={this.confirmAdding}
          onDuplicate={this.startDuplication}/>
      </div>
    );
  }
}

export default StacksApp;
