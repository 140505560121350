import React from 'react';
import Resource from './Resource'

const ResourceList = (props) => {
  return (
    <ul className="stack-item-list">
      {
        props.resources && props.resources.map((resource, i) =>
          <Resource {...resource} key={i} index={i} isEditing={props.isEditing} onDelete={props.onDelete(resource)} onMoveResourceUp={(props.onMoveResourceUp)} onMoveResourceDown={(props.onMoveResourceDown)}/> 
        )
      }
    </ul>
  );
}

export default ResourceList;

